const quiz = () => {
  const stepsArr = document.getElementsByClassName("quiz__card");
  const stepsCall = stepsArr.length;
  const nextBtn = document.querySelector(".quiz__btn");
  const step = document.querySelector(".quiz__title-step");
  let stepNumb = 1;

  function nextStep() {
    if (stepNumb < stepsCall) {
      stepsArr[stepNumb-1].classList.remove("quiz__card__active");
      stepNumb++;
      step.innerHTML=stepNumb+"/4"
      stepsArr[stepNumb-1].classList.add("quiz__card__active");
      nextBtn.disable = true;
      if (stepNumb == stepsCall) {
        step.innerHTML=""
        nextBtn.type = "submit";
        nextBtn.innerHTML = "Хочу получить";
      }
    } 
  }
  nextBtn.addEventListener("click", nextStep);
};

export default quiz;
