const sliders = (slides, next) => {

    const items = document.querySelectorAll(slides);
    let slideIndex = 1;

    function showSlides(n) {
        if (n >= items.length) {
            slideIndex = 1;
        }

        if (n < 1) {
            slideIndex = items.length;
        }

        items.forEach(item => {
            item.style.display = "none";
        });

        items[slideIndex - 1].style.display = 'block';
        if (document.documentElement.clientWidth > 780) {
            items[slideIndex].style.display = 'block';
        }

    }

    showSlides(slideIndex);

    function plusSlides(n) {
        showSlides(slideIndex += n);
    }

    try {
        const nextBtn = document.querySelector(next);

        nextBtn.addEventListener('click', () => {
            plusSlides(1);
        });
    } catch (e) { }
};

export default sliders;