import burger from "./modules/burger";
import scrollTo from "./modules/scroll";
import mask from "./modules/mask";
import sliders from "./modules/sliders";
import accordion from "./modules/accordion";
import quiz from "./modules/quiz";
import popup from "./modules/popup";

window.addEventListener("DOMContentLoaded", () => {
  "use strict";

  (function () {
    const header = document.querySelector(".header");
    if (document.documentElement.clientWidth < 780) {
      header.classList.add("header__active");
    }
    window.onscroll = () => {
      if (
        document.documentElement.clientWidth > 780 &&
        window.pageYOffset > 50
      ) {
        header.classList.add("header__active");
      } else if (document.documentElement.clientWidth > 780) {
        header.classList.remove("header__active");
      }
    };
  })();
  if (document.documentElement.clientWidth > 780) {
    (function () {
      const cards = document.querySelectorAll(".failure__card");
      const hovers = document.querySelectorAll(".failure__card-hover");
      for (let i = 0; i < cards.length; i++) {
        cards[i].addEventListener("mouseenter", (event) => {
          event.preventDefault();
          if (event.target.classList[0] === "failure__card") {
            let target = event.target;
            target.classList.toggle("failure__card-active");
            target.nextElementSibling.classList.toggle("failure__card-active");
          }
        });
      }
      for (let i = 0; i < cards.length; i++) {
        hovers[i].addEventListener("mouseleave", (event) => {
          event.preventDefault();
          if (event.target.classList[1] === "failure__card-active") {
            let target = event.target;
            target.classList.toggle("failure__card-active");
            target.previousElementSibling.classList.toggle(
              "failure__card-active"
            );
          }
        });
      }
    })();
  }

  burger(".burger", ".header__nav", ".nav__close", ".nav__item-link");
  scrollTo();
  mask(".form__input__tel");
  sliders(".case__card", ".next");
  accordion("works__item");
  quiz();
  popup(".btn__modal", ".popup", ".popup .popup__close");
});
