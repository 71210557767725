const accordion = (tabSelector) => {
    let acc = document.getElementsByClassName(tabSelector),
        i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function () {
            let cla = this.querySelector('.works__item-text'),
                mark = this.querySelector('.marker__plus');
            cla.classList.toggle("works__item-text__active");
            mark.classList.toggle("marker__plus__active");
        });
    }
}

export default accordion;