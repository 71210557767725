const popup = (triggerSelector, modalSelector, closeSelector) => {
    const trigger = document.querySelectorAll(triggerSelector),
        modal = document.querySelector(modalSelector),
        close = document.querySelector(closeSelector),
        windows = document.querySelectorAll('[data-modal]');

    let btnPressed = false;

    trigger.forEach(item => {
        item.addEventListener('click', (e) => {
            if (e.target) {
                e.preventDefault();
            }

            btnPressed = true;

            windows.forEach(item => {
                item.style.display = 'none';
            });

            modal.style.display = "block";
            document.body.style.overflow = "hidden";
        });
    });

    close.addEventListener('click', () => {
        windows.forEach(item => {
            item.style.display = 'none';
        });

        modal.style.display = "none";
        document.body.style.overflow = "";
    });

    modal.addEventListener('click', (e) => {
        if (e.target === modal) {
            windows.forEach(item => {
                item.style.display = 'none';
            });

            modal.style.display = "none";
            document.body.style.overflow = "";
        }
    });
};

export default popup;